export const newrelicErrLogger = (
  error: Error,
  customObj?: Record<string, unknown>
) => {
  if (
    typeof window.newrelic !== 'undefined' &&
    typeof window.newrelic.noticeError !== 'undefined'
  ) {
    window.newrelic.noticeError(error, customObj)
  } else {
    console.warn(
      'newrelic logger is not initialized, we are not able to track the error'
    )
    console.error(error, customObj)
  }
}
