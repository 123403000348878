export enum Environments {
  prod = 'production',
  dev = 'development',
  test = 'test',
  qa = 'qa',
  stg = 'staging',
}

export enum SubscriptionStatus {
  PendingFulfillmentStart = 'PendingFulfillmentStart',
  Subscribed = 'Subscribed',
  Unsubscribed = 'Unsubscribed',
  Suspended = 'Suspended',
}

export enum SubscriptionStatusDisplayValue {
  PendingFulfillmentStart = 'Pending Fulfillment Start',
  Subscribed = 'Subscribed',
  Unsubscribed = 'Unsubscribed',
  Suspended = 'Suspended',
}

export const customerCollectionFormApi =
  import.meta.env.VITE_CMCS_SERVICE_URL ??
  'https://qa.api.labra.io/marketplace/v1'

export const customerCollectionFormApiGcp =
  import.meta.env.VITE_CMCS_SERVICE_URL_GCP ??
  'https://qa.api.labra.io/gcp_marketplace/v1'

export const defaultRedirectUrl = 'https://app.labra.io/'

export const supportCalendlyLink = import.meta.env.VITE_SUPPORT_CALENDLY_LINK

export const giftcardRedeemEndpoint =
  import.meta.env.VITE_GIFTCARD_REDEEM_ENDPOINT ??
  'https://xpn0o4ckjg.execute-api.us-east-1.amazonaws.com/amazon-gift-card'

export const azureMarketplaceServiceUrl =
  import.meta.env.VITE_AZURE_MARKETPLACE_SERVICE_URL ??
  'https://qa.api.labra.io/azure_marketplace/v1'

export const mockServerEnabled = JSON.parse(
  import.meta.env.VITE_ENABLE_MOCK_SERVER ?? 'false'
)
export const defaultButtonColor = '#2814FF'
export const defaultButtonTextColor = '#FFFFFF'

export const noneQuantity = 'None'
