import axios from 'axios'
import {
  customerCollectionFormApi,
  azureMarketplaceServiceUrl,
  customerCollectionFormApiGcp,
} from '../utils/constants'
import { CloudType } from '../modules/types'
export const API = axios.create()

const getCloudURL = (cloudProvider: string) => {
  let cloudUrl: string
  switch (cloudProvider) {
    case CloudType.GCP:
      cloudUrl = customerCollectionFormApiGcp
      break
    default:
      cloudUrl = customerCollectionFormApi
  }
  return cloudUrl
}

export const fetchCustomerCollectionData = (
  buyerId: string,
  cloudProvider: string,
  getBuyerDetails: boolean
) => {
  const cloudUrl: string = getCloudURL(cloudProvider)
  return API.get(`${cloudUrl}/buyer_form`, {
    params: { buyer_id: buyerId, get_buyer_details: getBuyerDetails },
  })
}

export const submitCustomerCollectionData = (
  data: unknown,
  buyerId: string,
  cloudProvider: string
) => {
  const cloudUrl: string = getCloudURL(cloudProvider)
  return API.post(`${cloudUrl}/buyer_form`, data, {
    params: { buyer_id: buyerId },
  })
}

export const fetchCustomerCollectionAzure = (
  buyerId: string,
  subscriptionId: string
) =>
  API.get(
    `${azureMarketplaceServiceUrl}/buyers/${buyerId}/subscriptions/${subscriptionId}`
  )

export const submitCustomerCollectionDataAzure = (
  data: unknown,
  buyerId: string,
  subscriptionId: string
) =>
  API.post(
    `${azureMarketplaceServiceUrl}/buyers/${buyerId}/subscriptions/${subscriptionId}/activate`,
    data
  )
