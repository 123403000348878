import { AxiosResponse } from 'axios'

export const getErrorMessages = (defaultMessages: string[]) => (
  response?: AxiosResponse<ErrorResponse>
) => {
  const errorMessages = response?.data?.errors
  if (Array.isArray(errorMessages)) {
    return errorMessages.join(', ') || defaultMessages.join(', ')
  } else {
    return defaultMessages.join(', ')
  }
}
